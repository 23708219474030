/**
 * @author James Hansen
 */

function pvc_search(id, results_id, params){
	return ;
	// Assign mootools to $.
	var $ = document.id;
	var searchEl = $(id);
	searchEl.setProperty( "autocomplete", "off" );
	var req = null, rq = null;
	var curtime = new Date();
	var url = params.baseURI+'index.php?tmpl=component';
	
	// Close it if clicked or touch else where.
	$(window).addEvent('click', function(e){
		$(results_id).empty().removeClass('pvc_results').setStyle('visibility', 'hidden');
	});
	
	
	searchEl.onkeyup = function(){
		var resultsEl = $(results_id);
		resultsEl.removeClass('pvc_results');
		resultsEl.setStyle('visibility', 'hidden');
		
		var value = searchEl.value;
		if(value == ''){
			$(results_id).empty().removeClass('ps_results').setStyle('visibility', 'hidden');
		}else if(value.length > 3){
			
			var data = {
				'option' : params.option || 'com_publivateconversations',
				'view' : params.view || 'dedup',
				'format': 'json',
				'rlang' : params.lang,
				'layout' : params.layout || 'autocomplete',
				'Itemid' : params.Itemid,
	//			'searchphrase' : _optPhrase, //any,all,exact
				'search' : value,
				'idea_layout' : params.idea_layout || null,
				'r' : curtime.getTime()
			};
			
			if($('challenge_id')){
				data.challenge_id = $('challenge_id').value;
			}
			
			req = new Request.JSON({
				url : url,
				method: 'get',
				data : data,
				onSuccess: function(results) {
					// Add Results main function
					var resultsEl = $(results_id);

					if(results.status == 2) return; // No Results;

					resultsEl.empty().removeClass('pvc_results').setStyle('visibility', 'visible');

					resultsEl.addClass('pvc_results');
					var res_header = new Element('div', {'class': 'pvc_header' }).set('html', params.txtHeader).inject(resultsEl);
					// Create close button link.
					var link = new Element('a', { 'class' :'pvc_close_link' , 'id': results_id + '_psv_close_link' }).setProperty('href','#').set('html', params.txtClose).inject(res_header, 'before');
					link.addEvent('click', function(e){
						resultsEl.empty().removeClass('pvc_results').setStyle('visibility', 'hidden');
						e.stop();
					});
					
					var data = new Element('div', { 'class' : 'pvc_results_inner'} ).set('html', results.content);
					data.inject(resultsEl);

					var div = new Element('div', {'class' : 'pvc_view_all'}).inject(resultsEl);
					if(params.showViewAllLink == 1 ){
						var link;
						if(params.viewAllLink){
							link = params.viewAllLink;
							var searchname = ( params.searchname || 'search' );
							link += '&' +  searchname + '=' + value;
						}else{
							var query = [];
							query.push( 'option' + '=' +  ( params.option || 'com_publivateconversations'));
							query.push( 'view' + '=' + (params.view || 'dedup'));
							query.push( 'layout' + '=' + (params.layout || 'autocomplete'));
							query.push( 'format' + '=' + 'raw');
							query.push( 'lang' + '=' + params.lang);
							query.push( 'Itemid' + '=' + params.Itemid);
							query.push( (params.searchname || 'search') + '=' + value);
							link = 'index.php?' + query.join('&');
						}
						var a = new Element('a', { 'href'  : link }).inject(div);
						var span = new Element('span').set('html', params.txtViewAll);
						span.inject(a);
					}
					
					jQuery('#'+results_id+' a').last().blur(function(e){
						$(results_id).empty().removeClass('pvc_results').setStyle('visibility', 'hidden');
						this.tabindex = null;
					});

				}
			});
			if(req){
//				rq = req.request.delay(200, req);
				req.send();
			}
		}
	}

	searchEl.onkeydown = function(){
		if(req) req.cancel();
	};
}