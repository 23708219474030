/** 
 * Fixes touch help 
 */
(function ($) {
	$.fn.pv_help = function () {

		if (!$('html').hasClass('no-touch')) {
			$('body').click(function (e) {
				jQuery('.pv-help-inner').hide();
			});
			this.click(function (e) {
				e.stopPropagation();
				e.preventDefault();
				$(this).find('.pv-help-inner').toggle();
			});
		}
		return this;
	};
// *********************************************************************************
// System Message Fading.
// *********************************************************************************
	$.fn.system_message = function (options) {
		var settings = $.extend({
			// These are the defaults.
			timeout: 4500,
			fadeout: true
		}, options);
		var message = $('#system-message-container');
		var slideOut = null;
		if (settings.fadeout && $.trim(message.html()) ) {
			slideOut = setTimeout(function () {
				message.slideUp();
			}, settings.timeout);
		}

		return this;
	};
}(jQuery));


/**
 * Remove no-js while loading.
 */
(function () {
	var element = document.documentElement;
	var css = element.getAttribute('class');
	element.setAttribute('class', css.replace('no-js', ''));
})();

jQuery(function ($) {

	$('.pv-help a').pv_help();

	$('body').system_message({
		timeout: 4000,
		fadeout: true,
	});

	/*Enalbe Register button when Terms of aggrement has been checked.*/
	function toggleRegister(status) {
		if (status == true) {
			jQuery('.cbRegistrationSubmit').prop('disabled', false);
		} else {
			jQuery('.cbRegistrationSubmit').prop('disabled', true);
		}
	}

	if (jQuery('#acceptedterms').size()) {
		toggleRegister(jQuery('#acceptedterms').prop('checked'));
		jQuery('#acceptedterms').click(function (e) {
			toggleRegister(this.checked)
		});
	}



	//Disable background scroll when menu is open.
	var isMenuClicked = false;
	jQuery('#nav-search-menu').click(function (e) {
		jQuery('body').css('position', 'fixed');
		jQuery('body').css('overflow', 'hidden');
		isMenuClicked = true;
		return true;
	});

	jQuery(document).on('click touchstart',
			function (e) {
				if (isMenuClicked) {
					isMenuClicked = false;
					return false;
				}

				if (jQuery('#mb-pnl').css('display') == 'none') {
					jQuery('body').css('position', 'initial');
					jQuery('body').css('overflow', 'initial');
				}
			}
	);
});
